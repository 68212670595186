import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

import Syllabics from "../components/Syllabics"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"

import BackgroundImage2 from "../components/BackgroundImage"
import PageSection from "../components/global/PageSection"

import BlockContentPrimary from "../components/blockContentPrimary"

// Component returns sections with photos floated on alternating sides
const InnerContent = ({ pageSections = [] }) => {
  if (!!pageSections.length) {
    return pageSections.map((pageSection, index) => (
      <section key={`${pageSection._key}-${index}`} className={` max-w-6xl`}>
        {pageSection?.sectionImage?.asset?.fluid && (
          <div
            className={` ${
              !!index % 2 === 0
                ? "md:float-right md:ml-10"
                : "md:float-left md:mr-10"
            } m-auto   w-84 md:w-96 mb-10 `}
          >
            <GatsbyImage fluid={pageSection?.sectionImage?.asset?.fluid} />
          </div>
        )}
        <div className=" ">
          {!!pageSection?.sectionTitle && (
            <h1 className="text-center">{pageSection.sectionTitle}</h1>
          )}
          <BlockContentPrimary blockData={pageSection._rawSectionText} />
        </div>
      </section>
    ))
  }
  return null
}

const ActiveLiving = ({ data }) => {
  const { sanityActiveLiving } = data
  const { _rawMainContent: rawMainContent } = sanityActiveLiving

  const hero = data.heroDefault.childImageSharp
  return (
    <Layout>
      <SEO title="Active Living" />
      {/* Below Mimics the <Hero> component, but has a few different needs */}
      <div className="flex items-center h-auto  justify-center overflow-hidden">
        <BackgroundImage2 heroImage={hero}>
          <div className="flex flex-col mx-auto mb-10 sm:my-20  ">
            <h1 className="flex flex-col lg:flex-row p-4 font-heading bg-white bg-opacity-75 text-center justify-center mt-10">
              <span className="tracking-tight lg:ml-2 mb-2 text-4xl text-black">
                Active Living
              </span>
            </h1>
          </div>
        </BackgroundImage2>
      </div>
      <PageSection
        className=" justify-center "
        innerContent={
          <div className="text-primary-dark max-w-6xl">
            {rawMainContent && (
              <BlockContentPrimary blockData={rawMainContent} />
            )}

            {!!sanityActiveLiving.pageSections.length && (
              <InnerContent pageSections={sanityActiveLiving.pageSections} />
            )}
          </div>
        }
        // outerContent={<div>Outer</div>}
      />
    </Layout>
  )
}

export default ActiveLiving

export const query = graphql`
  query ActiveLivingQuery {
    heroDefault: file(
      relativePath: { eq: "andrey-trusov-PPfMBZX_PaA-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityActiveLiving {
      englishTitle
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      pageSections {
        _key
        sectionTitle

        _rawSectionText(resolveReferences: { maxDepth: 10 })
        sectionImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
